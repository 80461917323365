<template>
 <div class="carousel">
  <b-button  v-show="currentIndex > 0" @click="previous" class="button-carousel">{{ precedente }}</b-button>
    <b-card
      v-for="(item, index) in visibleCards"
      :key="index"
      class="card mx-1 p-2 bg-secondary text-white"
    >
      <div class="d-block img-fluid h-100 w-auto">
        <h5 class="text-primary">{{ item.caption }}</h5>
        <p>{{ item.text }}</p>
      </div>
    </b-card>
    <b-button v-show="currentIndex + cardsPerPage < cards.length" @click="next" class="button-carousel">{{ successivo }}</b-button>
  </div>
</template>


<script>
export default {
  name: "ServiceTextBoxes",
  props: {
    services: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 1024,
    },
    height: {
      type: Number,
      default: 480,
    },
  },
  data() {
    return {
      precedente : '<',
      successivo : '>',
      cards: this.services.map(function (service) {
        return {
          caption: service.name,
          text: service.description,
        };
      }),
      currentIndex: 0,
      cardsPerPage: 2,
    };
  },
  computed: {
    visibleCards() {
      return this.cards.slice(this.currentIndex, this.currentIndex + this.cardsPerPage);
    },
  },
  methods: {
    next() {
      if (this.currentIndex + this.cardsPerPage >= this.cards.length) return;
      this.currentIndex += this.cardsPerPage;
    },
    previous() {
      if (this.currentIndex <= 0) return;
      this.currentIndex -= this.cardsPerPage;
    },
  },
};
</script>


<style scoped>
.button-carousel{
  height: 35px;
  width: 50;
  position: relative;
}

.carousel {
  display: flex;
  align-items: center;
}

.card {
  flex: 1;
  max-width: 50%;
  min-height: 250px;
}
</style>



<!-- <template>
  <b-card-group deck class="mb-0">
    <b-card
      v-for="(item, index) in cards"
      :key="index"
      class="mx-1 p-2 bg-secondary text-white"
    >
      <div class="d-block img-fluid h-100 w-auto">
        <h5 class="text-primary">{{ item.caption }}</h5>
        <p>{{ item.text }}</p>
      </div>
    </b-card>
  </b-card-group>
</template> -->



<!-- 
<script>
export default {
  data() {
    return {
      cards: this.services.map(function (service) {
        return {
          caption: service.categoria.name || service.slug,
          text: service.description,
        };
      }),
      currentIndex: 0,
      cardsPerPage: 2,
    };
  },
  computed: {
    visibleCards() {
      return this.cards.slice(this.currentIndex, this.currentIndex + this.cardsPerPage);
    },
  },
  methods: {
    next() {
      if (this.currentIndex + this.cardsPerPage >= this.cards.length) return;
      this.currentIndex += this.cardsPerPage;
    },
    previous() {
      if (this.currentIndex <= 0) return;
      this.currentIndex -= this.cardsPerPage;
    },
  },
};
</script> -->


<!-- <style scoped>
/deep/ .card-body {
  padding: 0;
}
</style> -->
