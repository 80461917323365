var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "jumbotron m-0 p-0 py-3 jumbotron-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 col-lg-12" },
          [
            _vm.old_services
              ? _c("ServicesCarousel", {
                  attrs: { services: _vm.old_services }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "container my-4" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _vm.services.items
              ? _c("ServiceTextBoxes", {
                  attrs: { services: _vm.old_services }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row mt-4" }, [
        _c("div", { staticClass: "col-md-4 px-0" }, [
          _c("img", {
            staticClass: "w-100 h-auto",
            attrs: {
              src: require("@/assets/images/home_01.png"),
              alt: _vm.$t("app_name")
            }
          })
        ]),
        _c("div", { staticClass: "col-md-8 pr-0 pl-0 pl-md-3" }, [
          _c(
            "div",
            { staticClass: "p-4 bg-secondary text-white" },
            [
              _vm.services.items
                ? _c("RequestServiceSearchForm", {
                    attrs: { services: _vm.services.items }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "row mt-4" }, [
        _c("div", { staticClass: "col-md-4 px-0" }, [
          _c(
            "div",
            { staticClass: "p-4 bg-secondary text-white" },
            [
              _c("h4", [_vm._v("Vuoi più informazioni?")]),
              _c("h5", [_vm._v("Scrivici")]),
              _c("ContactForm")
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-md-8 pr-0" }, [
          _c("img", {
            staticClass: "w-100 h-auto",
            attrs: {
              src: require("@/assets/images/home_02.png"),
              alt: _vm.$t("app_name")
            }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }