var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carousel" },
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentIndex > 0,
              expression: "currentIndex > 0"
            }
          ],
          staticClass: "button-carousel",
          on: { click: _vm.previous }
        },
        [_vm._v(_vm._s(_vm.precedente))]
      ),
      _vm._l(_vm.visibleCards, function(item, index) {
        return _c(
          "b-card",
          { key: index, staticClass: "card mx-1 p-2 bg-secondary text-white" },
          [
            _c("div", { staticClass: "d-block img-fluid h-100 w-auto" }, [
              _c("h5", { staticClass: "text-primary" }, [
                _vm._v(_vm._s(item.caption))
              ]),
              _c("p", [_vm._v(_vm._s(item.text))])
            ])
          ]
        )
      }),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentIndex + _vm.cardsPerPage < _vm.cards.length,
              expression: "currentIndex + cardsPerPage < cards.length"
            }
          ],
          staticClass: "button-carousel",
          on: { click: _vm.next }
        },
        [_vm._v(_vm._s(_vm.successivo))]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }