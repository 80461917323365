<template>
  <div>
    <div class="jumbotron m-0 p-0 py-3 jumbotron-fluid">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <!-- Carousel  -->
          <ServicesCarousel v-if="old_services" :services="old_services" />
        </div>
      </div> 
    </div>
    <div class="container my-4">
      <div class="row">
        <!-- Services description -->
        <div class="col-md-12">
          <ServiceTextBoxes v-if="services.items" :services="old_services" />
        </div>
      </div>
      <!-- Image + Services Box -->
      <div class="row mt-4">
        <div class="col-md-4 px-0">
          <img
            class="w-100 h-auto"
            src="@/assets/images/home_01.png"
            :alt="$t('app_name')"
          />
        </div>
        <div class="col-md-8 pr-0 pl-0 pl-md-3">
          <div class="p-4 bg-secondary text-white">
            <RequestServiceSearchForm
              v-if="services.items"
              :services="services.items"
            />
          </div>
        </div>
      </div>
      <!-- Form Box + Images -->
      <div class="row mt-4">
        <div class="col-md-4 px-0">
          <div class="p-4 bg-secondary text-white">
            <h4>Vuoi più informazioni?</h4>
            <h5>Scrivici</h5>
            <ContactForm />
          </div>
        </div>
        <div class="col-md-8 pr-0">
          <img
            class="w-100 h-auto"
            src="@/assets/images/home_02.png"
            :alt="$t('app_name')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import ServicesCarousel from "@/components/shared/ServicesCarousel";
import ServiceTextBoxes from "@/components/ServiceTextBoxes";
import RequestServiceSearchForm from "@/components/forms/services/RequestServiceSearchForm";
import RequestServiceFormAffilate from "@/components/forms/services/RequestServiceFormAffiliate";
import ContactForm from "@/components/forms/contact/ContactForm";
import { mapActions } from "vuex";
import {test} from "@/services/service.service";


export default {
  name: "Home",
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.home"),
    meta: [{ name: "description", content: i18n.t("menu.home") }],
  },
  components: {
    ServicesCarousel,
    ServiceTextBoxes,
    RequestServiceSearchForm,
    ContactForm,
    RequestServiceFormAffilate
  },
  data() {
    return {
      currentIndex: 0,
      perPage: 2,
      cards: [
        { id: 1, text: 'Card 1' },
        { id: 2, text: 'Card 2' },
        { id: 3, text: 'Card 3' },
        // ...
      ],
      old_services: [
        {name: "Babysitter", description: "Le nostre babysitter accudiscono e controllano i vostri figli. Trascorrono alcune ore o l’intera giornata con loro, si assicurano che mangino, studino e facciano tutti i compiti loro assegnati dai genitori.", image: require("@/assets/images/services/babysitter.png")},
        {name: "Colf", description: "La colf si occupa delle faccende domestiche, di pulire e mantenere ordinata la casa. Lavora con ordine e precisione in modo da fornire un servizio di qualità e seguendo le direttive che le vengono impartite.", image: require("@/assets/images/services/colf.png")},
        {name: "Assistenza domiciliare", description: "L’Assistente Domiciliare svolge un’attività di assistenza e compagnia a domicilio per qualche ora, per l’intera giornata e/o per la notte, prendendosi cura della persona e aiutandola nelle sue attività quotidiane, nell’alzarsi, lavarsi, cucinare, etc.", image: require("@/assets/images/services/assistenza_domiciliare.png")},
        {name: "Notti in ospedale", description: "Il nostro personale trascorre la notte in ospedale accudendo il paziente, prestandogli le dovute attenzioni e gestendo qualsiasi situazione che necessiti un loro intervento diretto o il coinvolgimento del personale ospedaliero.", image: require("@/assets/images/services/notti_in_ospedale.png")},
        {name: "Presenza diurna a domicilio", description: "Il servizio di presenza diurna, consente di avere un assistente domiciliare dalle 08.00 alle 20.00 garantendo aiuto durante le attività di alzata e igiene.", image: require("@/assets/images/services/presenza.png")},
        {name: "Presenza notturna a domicilio", description: "Il servizio di presenza notturna garantisce la presenza dalle 20.00 alle 08.00 partendo dalla essa a letto e fornendo aiuto durante la notte.", image: require("@/assets/images/services/presenza.png")},
      ]
    };
  },
  computed: {
    // loading() {
    //   console.log("CIRICIAO")
    //   return this.$store.getters["services/loading"];
    // },
    services() {
      console.log("CIRICIAO")
      console.log(this.$store.state)
      return this.$store.state.services.all;
    },
  },
  methods: {
    ...mapActions("services", ["test"]),
    prevPage() {
      if (this.currentIndex > 0) {
        this.currentIndex -= this.perPage;
      }
    },
    nextPage() {
      if (this.currentIndex + this.perPage < this.cards.length) {
        this.currentIndex += this.perPage;
      }
    },
    loadServices(userId) {
          // Call the test method instead of getAll
          test(userId).then(
            (services) => {
              // Process the fetched services
              this.$store.commit("services/getAllSuccess", services);
            },
            (error) => {
              // Handle error if necessary
              console.error("Error fetching services:", error);
          }
      );
    },
  },
  mounted() {
    const loggedUser = this.$loggedUser();
    //const loggedUserId = this.$loggedUserId().id;
    if (!loggedUser) {
      // Load from API
      console.log("Loading services from API");
      this.loadServices(2);
    } else {
      this.loadServices(loggedUser.id);
    }
  },
};
</script>

<style scoped>
h1,
h2 {
  margin: 0;
}

h3 {
  margin-top: 0.5rem;
}
</style>
