var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-email",
                label: "Email",
                "label-for": "input-email",
                description:
                  "Non condivideremo mai la tua password con nessuno."
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-email",
                  type: "email",
                  placeholder: "Inserisci la tua email",
                  required: "",
                  state: _vm.validateState("email"),
                  "aria-describedby": "email-live-feedback"
                },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              }),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "email-live-feedback" } },
                [
                  !_vm.$v.form.email.required
                    ? _c("div", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("validation.required")) + " "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-name",
                label: "Nominativo",
                "label-for": "input-name"
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-name",
                  placeholder: "Nominativo",
                  autocomplete: "name",
                  required: "",
                  state: _vm.validateState("nominativo"),
                  "aria-describedby": "nominativo-live-feedback"
                },
                model: {
                  value: _vm.form.nominativo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nominativo", $$v)
                  },
                  expression: "form.nominativo"
                }
              }),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "nominativo-live-feedback" } },
                [
                  !_vm.$v.form.nominativo.required
                    ? _c("div", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("validation.required")) + " "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-messaggio",
                label: "Messaggio",
                "label-for": "input-messaggio"
              }
            },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "input-messaggio",
                  required: "",
                  state: _vm.validateState("messaggio"),
                  "aria-describedby": "messaggio-live-feedback"
                },
                model: {
                  value: _vm.form.messaggio,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "messaggio", $$v)
                  },
                  expression: "form.messaggio"
                }
              }),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "messaggio-live-feedback" } },
                [
                  !_vm.$v.form.messaggio.required
                    ? _c("div", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("validation.required")) + " "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { id: "input-group-privacy" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "privacy",
                    name: "privacy",
                    value: true,
                    "unchecked-value": false,
                    state: _vm.validateState("privacy"),
                    "aria-describedby": "privacy-live-feedback"
                  },
                  model: {
                    value: _vm.$v.form.privacy.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.form.privacy, "$model", $$v)
                    },
                    expression: "$v.form.privacy.$model"
                  }
                },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("Acconsento al trattamento dei dati")
                  ])
                ]
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  attrs: {
                    state: _vm.validateState("privacy"),
                    id: "privacy-live-feedback"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: { type: "reset", variant: "danger" }
                },
                [_vm._v("Azzera")]
              ),
              _c(
                "b-button",
                { attrs: { type: "submit", variant: "primary" } },
                [_vm._v("Invia")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showDebug
        ? _c(
            "b-card",
            { staticClass: "mt-3", attrs: { header: "Debug Form" } },
            [_c("pre", { staticClass: "m-0" }, [_vm._v(_vm._s(_vm.form))])]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }