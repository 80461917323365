<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group
        id="input-group-email"
        label="Email"
        label-for="input-email"
        description="Non condivideremo mai la tua password con nessuno."
      >
        <b-form-input
          id="input-email"
          v-model="form.email"
          type="email"
          placeholder="Inserisci la tua email"
          required
          :state="validateState('email')"
          aria-describedby="email-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="email-live-feedback">
          <div v-if="!$v.form.email.required">
            {{ $t("validation.required") }}
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-name"
        label="Nominativo"
        label-for="input-name"
      >
        <b-form-input
          id="input-name"
          v-model="form.nominativo"
          placeholder="Nominativo"
          autocomplete="name"
          required
          :state="validateState('nominativo')"
          aria-describedby="nominativo-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="nominativo-live-feedback">
          <div v-if="!$v.form.nominativo.required">
            {{ $t("validation.required") }}
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-messaggio"
        label="Messaggio"
        label-for="input-messaggio"
      >
        <b-form-textarea
          id="input-messaggio"
          v-model="form.messaggio"
          required
          :state="validateState('messaggio')"
          aria-describedby="messaggio-live-feedback"
        ></b-form-textarea>
        <b-form-invalid-feedback id="messaggio-live-feedback">
          <div v-if="!$v.form.messaggio.required">
            {{ $t("validation.required") }}
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="input-group-privacy">
        <b-form-checkbox
          id="privacy"
          name="privacy"
          v-model="$v.form.privacy.$model"
          :value="true"
          :unchecked-value="false"
          :state="validateState('privacy')"
          aria-describedby="privacy-live-feedback"
        >
          <span class="font-weight-bold"
            >Acconsento al trattamento dei dati</span
          >
        </b-form-checkbox>
        <b-form-invalid-feedback
          :state="validateState('privacy')"
          id="privacy-live-feedback"
        >
          {{ $t("validation.required") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="text-right">
        <b-button class="mr-2" type="reset" variant="danger">Azzera</b-button>
        <b-button type="submit" variant="primary">Invia</b-button>
      </div>
    </b-form>
    <b-card v-if="showDebug" class="mt-3" header="Debug Form">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ContactForm",
  mixins: [validationMixin],
  data() {
    return {
      form: this.getDefaultFormData(),
      showDebug: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: "account/sendingContactForm",
      error: "account/contactFormErrors",
    }),
  },
  validations: {
    form: {
      email: {
        required,
      },
      nominativo: {
        required,
      },
      messaggio: {
        required,
      },
      privacy: {
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    ...mapActions({
      sendContactForm: "account/sendContactForm",
    }),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getDefaultFormData() {
      return {
        email: null,
        nominativo: null,
        messaggio: null,
        privacy: false,
      };
    },
    async onSubmit(event) {
      event.preventDefault();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      await this.sendContactForm(this.form);
      this.form = this.getDefaultFormData();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form = this.getDefaultFormData();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
